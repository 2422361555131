const config = {
  TIMEZONE: "America/Sao_Paulo",
};

const envData = (ENV, local, test, production) => {
  if (ENV === "local") {
    return local;
  } else if (ENV === "test") {
    return test;
  } else if (ENV === "production") {
    return production;
  } else {
    console.log(new Error("Something went wrong with credentials"));
  }
};

export const ENV_TYPE = "production"; // local // test // production

export const BASE_URL = envData(
  ENV_TYPE,
  "http://localhost:3007/api/v1/",
  // "http://192.168.1.100:3007/api/v1/",
  "https://api.prosperityfinancial.app.br/api/v1/",
  "https://api.prosperityfinancial.app.br/api/v1/"
);
export const LINK_URL = envData(
  ENV_TYPE,
  "http://localhost:3000/document-verification/",
  "https://prosperityfinancial.app.br/document-verification/",
  "https://prosperityfinancial.app.br/document-verification/"
);
export const PAGE_LIMIT = 10;

// export const LINK_URL = "localhost:3003/document-verification/";

export default config;
