import React, { Suspense, useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import AfterAuth from "../HOC/AfterAuth";
import TableNavbar from "../components/TableNavbar";
import ContactTable from "../components/Contact/ContactTable";
import { getContactList } from "../helper/API/contact";
import Loader from "../components/Loader";
import { useRecoilState, useRecoilValue } from "recoil";
import { contactTableData } from "../recoil/Atoms";
import {
  contactPaginationData,
  toReloadContactData,
} from "../recoil/PaginationAtoms/Contact";
import { PAGE_LIMIT } from "../config";
import NewMemberAdd from "../components/Document/NewMemberAdd";

const ContactData = ({
  search,
  tableRow,
  refresh,
  setRefresh,
  setTableRow,
  searchResult,
  setSearchResult,
  filterVal,
  active,
  setActive,
}) => {
  // console.log("search", search);

  const tableData = useRecoilValue(
    contactPaginationData(searchResult ? search : (search = ""))
  );
  const [reloadVal, reloadData] = useRecoilState(toReloadContactData);
  const totalPage = Math.ceil((tableData?.totalFindData || 1) / PAGE_LIMIT);
  const [table, setTable] = useRecoilState(contactTableData);

  console.log("tableData", tableData);

  useEffect(() => {
    reloadData(reloadVal + 1);
    setSearchResult(false);
  }, [refresh]);
  useEffect(() => {
    // setTable(tableData?.findData);
    setTableRow(tableData?.findData);
  }, [tableData]);

  useEffect(() => {
    handleToggle();
  }, [filterVal]);

  const handleToggle = () => {
    if (filterVal === "Pending") {
      setActive({
        rejected: false,
        pending: true,
        approved: false,
        all: false,
      });

      const newData = tableData?.findData.filter((obj) => {
        if (obj.contactApprove === "pending") {
          return obj;
        }
      });
      setTableRow(newData);
    } else if (filterVal === "Approved") {
      setActive({
        rejected: false,
        pending: false,
        approved: true,
        all: false,
      });
      const newData = tableData?.findData.filter((obj) => {
        if (obj.contactApprove === "approved") {
          return obj;
        }
      });
      setTableRow(newData);
    } else if (filterVal === "rejected") {
      setActive({
        rejected: true,
        pending: false,
        approved: false,
        all: false,
      });
      const newData = tableData?.findData.filter((obj) => {
        if (obj.contactApprove === "rejected") {
          return obj;
        }
      });
      setTableRow(newData);
    } else {
      setActive({
        rejected: false,
        pending: false,
        approved: false,
        all: true,
      });
      setTableRow(tableData?.findData);
    }
  };

  return (
    <Suspense fallback={<Loader />}>
      <ContactTable
        tableRow={tableRow}
        refresh={refresh}
        setRefresh={setRefresh}
        tableDataArray={tableData}
        totalPage={totalPage}
      />
    </Suspense>
  );
};

const Contact = () => {
  const [tableRow, setTableRow] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState({
    rejected: false,
    pending: false,
    approved: false,
    all: true,
  });
  const [filterVal, setFilterVal] = useState("All");

  const [search, setSearch] = useState("");

  const [searchResult, setSearchResult] = useState(false);

  const [show, setShow] = useState(false);

  const onEnter = (e) => {
    if (e.key === "Enter") {
      // reloadData(reloadVal + 1);
      setSearchResult(true);
    } else {
      // setSearch("");
      setSearchResult(false);
    }
  };

  return (
    <>
      <AfterAuth>
        <div className="d-flex justify-content-between align-items-center mx-3 mx-md-5">
          <h2 className="mt-3">Clientes</h2>
          <Button
            onClick={() => {
              setShow(true);
            }}
            style={{
              backgroundColor: "#1C3D59",
              marginLeft: "8px",
            }}
            className="fw-bold align-items-center border-0"
          >
            +&nbsp;Novo&nbsp;cliente
          </Button>
        </div>
        <Card className="m-2 m-md-5 my-3 p-3 px-md-4">
          {/* <NAVBAR /> */}
          <TableNavbar
            title={"Contatos"}
            setSearch={setSearch}
            onEnter={onEnter}
            refresh={refresh}
            setRefresh={setRefresh}
            search={search}
            setActive={setActive}
            active={active}
          >
            <div className="">
              <Button
                className={`fs-color mx-2 border-0 ${
                  active.rejected ? "activeBtnTable" : "inActiveBtnTable"
                }`}
                onClick={(e) => setFilterVal("rejected")}
              >
                Reprovado
              </Button>
              <Button
                className={`fs-color mx-2 border-0 ${
                  active.pending ? "activeBtnTable" : "inActiveBtnTable"
                }`}
                onClick={(e) => setFilterVal("Pending")}
              >
                Aguardando
              </Button>
              <Button
                className={`fs-color  mx-2 border-0 ${
                  active.approved ? "activeBtnTable" : "inActiveBtnTable"
                }`}
                onClick={(e) => setFilterVal("Approved")}
              >
                Aprovado
              </Button>
              <Button
                className={`fs-color px-4 border-0 ${
                  active.all ? "activeBtnTable" : "inActiveBtnTable"
                }`}
                onClick={(e) => setFilterVal("All")}
              >
                Todos
              </Button>
            </div>
          </TableNavbar>

          <Suspense fallback={<Loader />}>
            <ContactData
              tableRow={tableRow}
              refresh={refresh}
              setRefresh={setRefresh}
              search={search}
              setTableRow={setTableRow}
              searchResult={searchResult}
              setSearchResult={setSearchResult}
              filterVal={filterVal}
              active={active}
              setActive={setActive}
            />
          </Suspense>
          {show && (
            <NewMemberAdd
              show={show}
              handleClose={() => setShow(false)}
              refresh={refresh}
              setRefresh={setRefresh}
            />
          )}
        </Card>
      </AfterAuth>
    </>
  );
};

export default Contact;
