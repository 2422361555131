import React, { useEffect, useMemo, useState } from "react";
import { Button, Table } from "react-bootstrap";
import {
	historyActivePageAtom,
	historyNextPageSelector,
	historyPrevPageSelector,
	historyShowFirstPageSelector,
	historyShowLastPageSelector,
} from "../../recoil/PaginationAtoms/History";
import Pagination from "../Pagination";
import NewPagination from "../Pagination/NewPagination";
import RecordFound from "../RecordFound";

const ProfileTable = ({ tableRow, tableDataArray, totalPage }) => {
	const [tableData, setTableData] = useState(tableRow);

	useEffect(() => {
		setTableData(tableRow);
	}, [tableRow]);
	// console.log("tableRow", tableRow);

	// console.log("currentTableData", currentTableData);

	return (
		<>
			<Table
				className='p-3 table-fit text-wrap tbl-color-text'
				responsive>
				{tableData?.length ? (
					<thead>
						<tr style={{ fontSize: "12px" }}>
							<th className='tbl-head-color ' width={"25%"}>
								Nome{" "}
							</th>
							<th className='tbl-head-color '>CPF</th>
							<th className='tbl-head-color '>CNPJ</th>
							<th className='tbl-head-color '>Telefone </th>
							<th className='tbl-head-color '>Data</th>
							<th className='tbl-head-color text-center'>
								Hora{" "}
							</th>
							<th className='tbl-head-color ps-3'>Status </th>
						</tr>
					</thead>
				) : (
					""
				)}
				{tableData?.length ? (
					<tbody>
						{tableData?.map((obj, i) => (
							<tr style={{ fontSize: "14px" }}>
								<td className='fw-bold'>{obj.name}</td>
								<td>{obj.CPF}</td>
								<td>{obj.CNPJ}</td>
								<td>{obj.phone}</td>
								<td>{obj.date}</td>
								<td className='text-center'>{obj.time}</td>
								<td className='text-end'>
									<Button
										style={{
											width: "100px",
											fontSize: "14px",
											borderRadius: "3px",
											backgroundColor: "#58A43D",
										}}
										className='border-0 p-0 fw-normal'
										size='md'>
										Respondido
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				) : (
					<RecordFound label='Nenhum Registro Encontrado' />
				)}
				<NewPagination
					show={
						tableDataArray?.filteredData?.length &&
						tableDataArray?.totalFindAdmin
					}
					atom={historyActivePageAtom}
					prevSelector={historyPrevPageSelector}
					nextSelector={historyNextPageSelector}
					showFirstSelector={historyShowFirstPageSelector}
					showLastSelector={historyShowLastPageSelector}
					totalPage={totalPage}
				/>
			</Table>
		</>
	);
};

export default ProfileTable;
