import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavbarCom from "../components/NavbarCom";
import Sidebar from "../components/Sidebar";

const AfterAuth = ({ children }) => {
  const [showSide, setShowSide] = useState(false);
  return (
    <div>
      <div
        style={{
          height: "54px",
          position: "sticky",
          top: "0",
          zIndex: "5000",
        }}
      >
        <NavbarCom showSide={showSide} setShowSide={setShowSide} />
      </div>

      <Row>
        <Col
          md={2}
          id="sidebar-wrapper"
          className="px-0"
          style={{
            overflow: "hidden",
          }}
        >
          <Sidebar
            showSide={showSide}
            setShowSide={() => {
              setShowSide(false);
            }}
          />
        </Col>
        <Col
          md={10}
          id="page-content-wrapper"
          className="py-2 py-md-4"
          style={{
            backgroundColor: "#DCDFE5",
            overflowY: "scroll",
            height: "100vh",
          }}
        >
          {children}
        </Col>
      </Row>
    </div>
  );
};

export default AfterAuth;
