import React, { Suspense, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Pagination from "react-bootstrap/Pagination";
import Card from "react-bootstrap/Card";
import { profileAtom } from "../../recoil/Atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import ProfileTable from "./ProfileTable";
import { profileHistory } from "../../helper/API/Profile";
import Loader from "../Loader";
import TableNavbar from "../TableNavbar";
import {
  historyPaginationData,
  toReloadHistoryData,
} from "../../recoil/PaginationAtoms/History";
import { PAGE_LIMIT } from "../../config";

const HistoryTable = ({
  tableRow,
  setTableRow,
  searchResult,
  setSearchResult,
  search,
}) => {
  const tableData = useRecoilValue(
    historyPaginationData(searchResult ? search : (search = ""))
  );
  const [reloadVal, reloadData] = useRecoilState(toReloadHistoryData);
  const totalPage = Math.ceil((tableData?.totalFindData || 1) / PAGE_LIMIT);

  console.log("tableData", tableData);
  console.log("searchResult", searchResult);

  useEffect(() => {
    reloadData(reloadVal + 1);
    setSearchResult(false);
  }, []);
  useEffect(() => {
    setTableRow(tableData?.filteredData);
  }, [tableData]);

  return (
    <ProfileTable
      tableRow={tableRow}
      tableDataArray={tableData}
      totalPage={totalPage}
    />
  );
};

const ProfileCard = ({
  showProfilePicture,
  showChangePassword,
  showAddAdmin,
  showAddBanker,
  permissions,
}) => {
  const profile = useRecoilValue(profileAtom);
  const [search, setSearch] = useState();
  const [refresh, setRefresh] = useState(0);
  const [tableRow, setTableRow] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState(false);

  // useEffect(() => {
  // 	setLoading(true);
  // 	const submitData = {
  // 		search,
  // 	};
  // 	profileHistory(submitData).then((res) => {
  // 		// console.log("res hello", res);
  // 		if (res.success) {
  // 			setLoading(false);
  // 			setTableRow(res.data);
  // 		} else {
  // 			setTableRow([]);
  // 			setLoading(false);
  // 		}
  // 	});
  // }, [refresh]);

  const onEnter = (e) => {
    if (e.key === "Enter") {
      // reloadData(reloadVal + 1);
      setSearchResult(true);
    } else {
      // setSearch("");
      setSearchResult(false);
    }
  };
  // console.log("tableRow", tableRow);

  return (
    <div>
      <Card className="m-2 m-md-5 my-3 p-3 px-md-4">
        <Row>
          <Col md={6}>
            <Row>
              {/* image */}
              <Col xs={12} sm={12} md={12} lg={5} className="">
                <div>
                  <img
                    src={
                      profile?.profileImage
                        ? profile?.profileImage
                        : "assets/img/noUser.png"
                    }
                    style={{
                      height: "180px",
                      width: "180px",
                      borderRadius: "10px",
                      objectFit: "cover",
                    }}
                    className="position-relative px-0"
                  />
                  <div>
                    <Button
                      className=""
                      onClick={showProfilePicture}
                      style={{
                        position: "absolute",
                        backgroundColor: "#85A6A2",
                        marginTop: "-25px",
                        marginLeft: "150px",
                        border: "0",
                      }}
                    >
                      <i className="bi bi-camera-fill"></i>
                    </Button>
                  </div>
                </div>
              </Col>
              {/* content */}
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={7}
                className=" d-flex align-items-center"
              >
                <div className="mt-4 mt-md-0">
                  <div className="border-left px-2 mx-2">
                    <span>
                      <p className="fs-6 fw-bolder mb-0">{profile?.name}</p>
                      <p className="small  mb-0">CEO</p>
                      <p className="small  mb-0">{profile?.email}</p>
                    </span>
                  </div>
                  <Button
                    onClick={showChangePassword}
                    className="bg-white border-0 "
                    style={{
                      color: "#85A6A2",
                      fontSize: "12px",
                    }}
                  >
                    Alterar senha
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
          {(permissions.newAdmin || permissions.newBanker) && (
            <Col md={6} className="text-md-end">
              {permissions.newAdmin ? (
                <Button
                  size="lg"
                  onClick={showAddAdmin}
                  className="border-0 fs-6 mt-2 me-md-2"
                  style={{ backgroundColor: "#C4CCD2" }}
                >
                  <i className="bi bi-link-45deg mx-1"></i>
                  Gerar código pra nova conta
                </Button>
              ) : null}
              {permissions.newBanker ? (
                <Button
                  size="lg"
                  onClick={showAddBanker}
                  className="border-0 fs-6 mt-2 me-md-2"
                  style={{ backgroundColor: "#C4CCD2" }}
                >
                  <i className="bi bi-link-45deg mx-1"></i>
                  Criar novo usuário do banco
                </Button>
              ) : null}
            </Col>
          )}
        </Row>
        {/* tabel */}
        {permissions.document && (
          <Row>
            <Col md={12} className="my-4">
              <Row>
                <Col xs={12} sm={12} md={2} lg={2}>
                  <h4 className="fw-bolder mt-3">Histórico</h4>
                </Col>
                <Col xs={12} sm={12} md={10} lg={10}>
                  <TableNavbar
                    setSearch={setSearch}
                    onEnter={onEnter}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    search={search}
                  />
                </Col>
              </Row>
            </Col>
            {/* tabel */}
            <Col md={12}>
              <Suspense fallback={<Loader />}>
                <HistoryTable
                  tableRow={tableRow}
                  search={search}
                  setTableRow={setTableRow}
                  searchResult={searchResult}
                  setSearchResult={setSearchResult}
                />
              </Suspense>
            </Col>
            <Col
              className="d-flex justify-content-center me-auto "
              md={12}
            ></Col>
          </Row>
        )}
      </Card>
    </div>
  );
};

export default ProfileCard;
