import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Row, Col, Spinner } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import {
  generateCode,
  inviteAdmin,
  registerBanker,
} from "../../helper/API/auth";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";

const AddBanker = ({ open, handleClose }) => {
  const [copyText, setCopyText] = useState(false);
  const [contact, setContact] = useState(false);
  const [hidePassword, setHidePassword] = useState(false);
  const [hideConfirmPassword, setHideConfirmPassword] = useState(false);
  const [document, setDocument] = useState(false);
  const [newAdmin, setNewAdmin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [designation, setDesignation] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [code, setCode] = useState(null);

  const [bankerFormValues, setBankerFormValues] = useState({
    name: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    generateCode().then((res) => {
      // console.log("res code", res);
      if (res.success) {
        setCode(res.data);
      }
    });
  }, []);

  // const handleCopy = (code) => {
  // 	if (code) {
  // 		copy(code);
  // 		setCopyText(true);
  // 		setTimeout(() => {
  // 			setCopyText(false);
  // 			console.log("called time out");
  // 		}, 1000);
  // 	}
  // };

  const handleBankerForm = (e) => {
    // console.log("handleForm", e.target.value);
    setBankerFormValues({
      ...bankerFormValues,
      [e.target.name]: e.target.value,
    });
  };

  const hidePwd = () => {
    setHidePassword(!hidePassword);
  };

  const registerUser = (event) => {
    setLoading(true);
    event.preventDefault();
    if (!bankerFormValues.name) {
      toast.error("Por favor, insira o nome");
      setLoading(false);
    } else if (!bankerFormValues.email) {
      toast.error("Por favor, insira o e-mail");
      setLoading(false);
    } else if (!bankerFormValues.password) {
      toast.error("Por favor, digite a senha");
      setLoading(false);
    } else if (!confirmPassword) {
      toast.error("Por favor, digite a senha de confirmação");
      setLoading(false);
    } else if (bankerFormValues.password !== confirmPassword) {
      toast.error("Senha não corresponde");
      setLoading(false);
    } else {
      registerBanker(bankerFormValues).then((res) => {
        if (res.success) {
          setLoading(false);
          toast.success(res.message);
          handleClose();
        } else {
          setLoading(false);
          toast.error(res.message);
        }
      });
    }
  };

  return (
    <>
      <Modal
        className="mt-5 zindex"
        show={open}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header
          closeButton
          className="border-0 mt-3 p-0 px-3"
          style={{ fontSize: "12px" }}
        >
          <Modal.Title className="fw-bold">
            Criar novo usuário do banco
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          {/* <Row>
            <Col md={10} className="fw-bolder fs-5 my-3 ">
              Criar novo usuário do banco
            </Col>
            <Col md={2} className="">
              <Button
                onClick={handleClose}
                className="border-0 text-dark p-0 mx-4 fs-4 bg-white "
              >
                <img src="assets/img/close.png"></img>
              </Button>
            </Col>
          </Row> */}

          <Col md={12} className="mt-1">
            <Form.Label className="fs-6 fw-bold">Nome</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1" className="p-2">
                <i
                  className="bi bi-person-fill"
                  style={{ color: "#CED4DB" }}
                ></i>
              </InputGroup.Text>
              <Form.Control
                placeholder="Primeiro e último nome"
                className="ps-0"
                name="name"
                onChange={handleBankerForm}
                aria-describedby="basic-addon1"
              />
            </InputGroup>

            <Form.Label className="fs-6 fw-bold">E-mail</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1" className="p-2">
                <i className="bi bi-envelope" style={{ color: "#CED4DB" }}></i>
              </InputGroup.Text>
              <Form.Control
                placeholder="E-mail"
                className="ps-0"
                name="email"
                onChange={handleBankerForm}
                aria-describedby="basic-addon1"
              />
            </InputGroup>

            <Form.Label className="fs-6 fw-bold">Senha</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1" className="p-2">
                <i className="bi bi-lock-fill" style={{ color: "#CED4DB" }}></i>
              </InputGroup.Text>
              <Form.Control
                placeholder="Sua senha"
                className="eye-logo ps-0"
                name="password"
                type={hidePassword ? "text" : "password"}
                onChange={handleBankerForm}
                aria-describedby="basic-addon1"
              />
              <InputGroup.Text id="basic-addon1" className="p-2">
                {hidePassword && (
                  <i
                    className="bi bi-eye-slash-fill"
                    style={{ color: "#CED4DB" }}
                    onClick={hidePwd}
                  ></i>
                )}

                {!hidePassword && (
                  <i
                    className="bi bi-eye-fill"
                    style={{ color: "#CED4DB" }}
                    onClick={hidePwd}
                  ></i>
                )}
              </InputGroup.Text>
            </InputGroup>

            <Form.Label className="fs-6 fw-bold">Repetir senha</Form.Label>
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1" className="p-2">
                <i className="bi bi-lock-fill" style={{ color: "#CED4DB" }}></i>
              </InputGroup.Text>
              <Form.Control
                placeholder="Sua senha"
                className="eye-logo ps-0"
                aria-describedby="basic-addon1"
                name="confirmPassword"
                type={hideConfirmPassword ? "text" : "password"}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <InputGroup.Text id="basic-addon1" className="p-2">
                {hideConfirmPassword && (
                  <i
                    className="bi bi-eye-slash-fill"
                    style={{ color: "#CED4DB" }}
                    onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
                  ></i>
                )}

                {!hideConfirmPassword && (
                  <i
                    className="bi bi-eye-fill"
                    style={{ color: "#CED4DB" }}
                    onClick={() => setHideConfirmPassword(!hideConfirmPassword)}
                  ></i>
                )}
              </InputGroup.Text>
            </InputGroup>
          </Col>
          <Col className="d-flex mt-1 justify-content-center">
            <Button
              className="login-btn px-5 py-2 fw-bold fs-4"
              onClick={registerUser}
              disabled={loading}
              type="submit"
            >
              Criar conta
              {loading && (
                <Spinner
                  animation="grow"
                  variant="light"
                  className="ms-3 py-2 fw-bold fs-4"
                />
              )}
            </Button>
          </Col>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddBanker;
