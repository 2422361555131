import React, { useEffect } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { NavLink } from "react-router-dom";
import { useRecoilState } from "recoil";
import { profileData } from "../helper/API/Profile";
import { profileAtom } from "../recoil/Atoms";

const NavbarCom = ({ setShowSide, showSide }) => {
  const [profileItem, setProfileItem] = useRecoilState(profileAtom);

  useEffect(() => {
    profileData().then((res) => {
      if (res.success) {
        setProfileItem(res.data);
      }
    });
  }, []);
  // console.log(profileItem);

  let pathName = window.location.pathname;

  return (
    <>
      {/* <Navbar collapseOnSelect expand="lg" className="nav px-3">
        <Navbar.Brand href="#home" className="ps-0">
          <img src="/assets/img/prosperity.png"></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav className="mx-4">
            <NavLink style={{ textDecoration: "none" }} to={"/perfil"}>
              <div
                className={`${
                  pathName == "/perfil" && "Nav-after"
                } text-white d-flex align-items-center`}
              >
                {profileItem?.name}
                <Nav.Link>
                  <img
                    src={
                      profileItem.profileImage
                        ? profileItem.profileImage
                        : "assets/img/noUser.png"
                    }
                    alt=""
                    style={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "50%",
                      objectFit: "contain",
                    }}
                  />
                </Nav.Link>
              </div>
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar> */}

      <Navbar className="nav p-0">
        <Row className="w-100 p-0 m-0">
          <Col md={2} lg={2} sm={1} xs={3}>
            <Navbar.Brand
              href="#home"
              className="ps-0 d-md-flex align-items-center justify-content-center d-none"
            >
              <img
                style={{ height: "60px" }}
                src="/assets/img/prosperity.png"
              ></img>
            </Navbar.Brand>
            <div
              id="menuList"
              onClick={() => {
                setShowSide(!showSide);
              }}
              className="ps-0 d-flex align-items-center h-100 justify-content-center d-md-none"
            >
              <img
                src="/assets/img/menuList.svg"
                style={{ width: "32px" }}
              ></img>
            </div>
          </Col>
          <Col md={10} lg={10} sm={10} xs={9} className="py-1">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Navbar.Brand className="ps-0 d-md-none align-items-center justify-content-center">
                <img
                  src="/assets/img/prosperity.png"
                  style={{ height: 60 }}
                ></img>
              </Navbar.Brand>
              <Nav className="me-auto"></Nav>
              <Nav className="mx-md-5 px-md-3">
                <NavLink style={{ textDecoration: "none" }} to={"/perfil"}>
                  <div
                    id="profile"
                    className={`${pathName == "/perfil" && "Nav-after"
                      } text-white d-flex align-items-center mt-md-1`}
                  >
                    <span className="d-none d-md-flex me-2">
                      {profileItem?.name}
                    </span>

                    <NavLink to={"/perfil"}>
                      <img
                        className={`${pathName == "/perfil" && "Nav-after"}`}
                        src={
                          profileItem.profileImage
                            ? profileItem.profileImage
                            : "assets/img/noUser.png"
                        }
                        alt=""
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "50%",
                          objectFit: "contain",
                        }}
                      />
                    </NavLink>
                  </div>
                </NavLink>
              </Nav>
            </Navbar.Collapse>
          </Col>
        </Row>
      </Navbar>
    </>
  );
};

export default NavbarCom;
