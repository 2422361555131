import React, { useEffect, useRef, useState, useMemo } from "react";
import Button from "react-bootstrap/Button";

import Table from "react-bootstrap/Table";
import {
	adminActivePageAtom,
	adminNextPageSelector,
	adminPrevPageSelector,
	adminShowFirstPageSelector,
	adminShowLastPageSelector,
} from "../../recoil/PaginationAtoms/AdminList";
import Pagination from "../Pagination";
import NewPagination from "../Pagination/NewPagination";
import RecordFound from "../RecordFound";
import PermissonTooltip from "./PermissonTooltip";

const PermissonTable = ({
	tableRow,
	refresh,
	activeTab,
	setRefresh,
	tableDataArray,
	totalPage,
}) => {
	const [show, setShow] = useState(false);
	const [target, setTarget] = useState(null);
	const [editData, setEditData] = useState(null);
	const [tableData, setTableData] = useState(tableRow);

	const ref = useRef(null);
	useEffect(() => {
		setTableData(tableRow);
	}, [tableRow]);

	const handleClick = (event, val, type, prmsn) => {
		// console.log("id", type);
		setShow(!show);
		setTarget(event.target);
		setEditData({
			...val,
			type,
			prmsn,
		});
	};

	const handleClose = () => {
		setShow(false);
	};

	// console.log(tableRow);
	return (
		<div>
			<Table
				className='p-3 table-fit text-wrap tbl-color-text'
				responsive>
				{tableData?.length ? (
					<thead>
						<tr>
							<th className='tbl-head-color' width={"20%"}>
								Nome{" "}
							</th>
							<th className='tbl-head-color'>Email</th>
							{activeTab == "admin" ? (
								<>
									<th className='tbl-head-color'>Função</th>
									<th className='tbl-head-color'>Clientes</th>
									<th className='tbl-head-color'>Documentos</th>
									<th className='tbl-head-color'>Nova conta</th>
									<th className='tbl-head-color'>Nova usuário do banco</th>
								</>
							) : (
								<th className='tbl-head-color'>Documentos</th>
							)}
						</tr>
					</thead>
				) : (
					""
				)}
				{tableData?.length ? (
					<tbody>
						{tableData?.map((val, i) => {
							return (
								<tr key={i} style={{ fontSize: "14px" }}>
									<td className='fw-bold'>{val.name}</td>
									<td>{val.email}</td>
									{activeTab == "admin" ? (
										<>
											<td>{val.designation}</td>
											<td>
												{val.permissions.contact ? (
													<Button
														onClick={(e) =>
															handleClick(
																e,
																val,
																"contact",
																true
															)
														}
														variant=' success'
														size='lg'
														style={{ fontSize: "14px" }}
														className='p-0 fw-bolder text-success border-0'>
														<span className='d-flex align-items-center'>
															<img
																src='/assets/img/right 2.png'
																className='px-1'
															/>
															Autorizar
														</span>
													</Button>
												) : (
													<Button
														onClick={(e) =>
															handleClick(
																e,
																val,
																"contact",
																false
															)
														}
														variant='danger'
														size='lg'
														style={{ fontSize: "14px" }}
														className='p-0 fw-bolder text-danger button-red'>
														<span
															className='d-flex align-items-center'
															style={{
																color: "#A43D3D",
															}}>
															<img
																src='/assets/img/wrong.png'
																className='px-1'
															/>
															Remover
														</span>
													</Button>
												)}
											</td>

											<td>
												{val.permissions.document ? (
													<Button
														onClick={(e) =>
															handleClick(
																e,
																val,
																"document",
																true
															)
														}
														variant=' success'
														size='lg'
														style={{ fontSize: "14px" }}
														className='align-items-center d-flex p-0 fw-bolder text-success  border-0'>
														<img
															src='/assets/img/right 2.png'
															className='px-1'
														/>
														Autorizar
													</Button>
												) : (
													<Button
														onClick={(e) =>
															handleClick(
																e,
																val,
																"document",
																false
															)
														}
														variant='danger'
														size='lg'
														style={{
															color: "#A43D3D",
															fontSize: "14px",
														}}
														className='p-0 fw-bolder       align-items-center d-flex button-red'>
														<img
															src='/assets/img/wrong.png'
															className='px-1'
														/>
														Remover
													</Button>
												)}
											</td>
											<td>
												{val.permissions.newAdmin ? (
													<Button
														onClick={(e) =>
															handleClick(
																e,
																val,
																"newAdmin",
																true
															)
														}
														variant=' success'
														size='lg'
														style={{ fontSize: "14px" }}
														className='p-0 fw-bolder text-success  border-0'>
														<span className='d-flex align-items-center'>
															<img
																src='/assets/img/right 2.png'
																className='px-1'
															/>
															Autorizar
														</span>
													</Button>
												) : (
													<Button
														onClick={(e) =>
															handleClick(
																e,
																val,
																"newAdmin",
																false
															)
														}
														variant='danger'
														size='lg'
														style={{ fontSize: "14px" }}
														className='p-0 fw-bolder text-danger button-red'>
														<span
															className='d-flex align-items-center'
															style={{
																color: "#A43D3D",
															}}>
															<img
																src='/assets/img/wrong.png'
																className='px-1'
															/>
															Remover
														</span>
													</Button>
												)}
												<PermissonTooltip
													show={show}
													target={target}
													ref={ref}
													handleClose={handleClose}
													editData={editData}
													refresh={refresh}
													setRefresh={setRefresh}
												/>
											</td>
											<td>
												{val.permissions.newBanker ? (
													<Button
														onClick={(e) =>
															handleClick(
																e,
																val,
																"newBanker",
																true
															)
														}
														variant=' success'
														size='lg'
														style={{ fontSize: "14px" }}
														className='p-0 fw-bolder text-success  border-0'>
														<span className='d-flex align-items-center'>
															<img
																src='/assets/img/right 2.png'
																className='px-1'
															/>
															Autorizar
														</span>
													</Button>
												) : (
													<Button
														onClick={(e) =>
															handleClick(
																e,
																val,
																"newBanker",
																false
															)
														}
														variant='danger'
														size='lg'
														style={{ fontSize: "14px" }}
														className='p-0 fw-bolder text-danger button-red'>
														<span
															className='d-flex align-items-center'
															style={{
																color: "#A43D3D",
															}}>
															<img
																src='/assets/img/wrong.png'
																className='px-1'
															/>
															Remover
														</span>
													</Button>
												)}
												<PermissonTooltip
													show={show}
													target={target}
													ref={ref}
													handleClose={handleClose}
													editData={editData}
													refresh={refresh}
													setRefresh={setRefresh}
												/>
											</td>
										</>
									) : (
										<td>
											{val.permissions.document ? (
												<Button
													onClick={(e) =>
														handleClick(
															e,
															val,
															"document",
															true
														)
													}
													variant=' success'
													size='lg'
													style={{ fontSize: "14px" }}
													className='align-items-center d-flex p-0 fw-bolder text-success  border-0'>
													<img
														src='/assets/img/right 2.png'
														className='px-1'
													/>
													Autorizar
												</Button>
											) : (
												<Button
													onClick={(e) =>
														handleClick(
															e,
															val,
															"document",
															false
														)
													}
													variant='danger'
													size='lg'
													style={{
														color: "#A43D3D",
														fontSize: "14px",
													}}
													className='p-0 fw-bolder       align-items-center d-flex button-red'>
													<img
														src='/assets/img/wrong.png'
														className='px-1'
													/>
													Remover
												</Button>
											)}
										</td>
									)}



								</tr>
							);
						})}
					</tbody>
				) : (
					<RecordFound label='Nenhum Registro Encontrado' />
				)}
			</Table>
			<NewPagination
				show={
					tableDataArray?.adminList?.length &&
					tableDataArray?.totalAdminList
				}
				atom={adminActivePageAtom}
				prevSelector={adminPrevPageSelector}
				nextSelector={adminNextPageSelector}
				showFirstSelector={adminShowFirstPageSelector}
				showLastSelector={adminShowLastPageSelector}
				totalPage={totalPage}
			/>
		</div>
	);
};

export default PermissonTable;
