import { Button, Modal, Spinner } from "react-bootstrap";
import { addNotes } from "../../../helper/API/document";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

const AddEditNotesModal = ({ open, handleClose, noteData }) => {
  const [message, setMessage] = useState(noteData?.notes);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setMessage(noteData?.notes);
  }, [noteData?.notes]);

  const toastMessage = noteData?.notes
    ? "Nota atualizada com sucesso"
    : "Nota adicionada com sucesso";

  const setNote = async () => {
    setLoading(true);
    if (message !== undefined) {
      const res = await addNotes({
        documentId: noteData?.id,
        message: message,
      });
      console.log("res ... ", res);
      if (res.success) {
        toast.success(toastMessage);
        setLoading(false);
        onClose();
      }
    } else {
      toast.error("Por favor preencha a nota");
      setLoading(false);
    }
  };

  const onClose = () => {
    setMessage("");
    handleClose();
  };

  return (
    <>
      <Modal show={open} centered className="zindex">
        <div className="d-flex justify-content-between align-items-center mx-3 mt-2">
          <h5 className="fw-bold mt-1">
            {noteData?.notes ? "Editar notas" : "Adicionar notas"}
          </h5>
          <img
            src="assets/img/close.png"
            height={15}
            width={15}
            onClick={onClose}
            style={{ cursor: "pointer" }}
          ></img>
        </div>
        <textarea
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          className="mx-3 my-2"
          rows={5}
        >
          {/* {noteData?.notes} */}
          {message}
        </textarea>
        <Button className="login-btn border-0 mx-3 my-2" onClick={setNote}>
          {noteData?.notes ? "Editar notas" : "Adicionar notas"}
          {loading && (
            <Spinner
              animation="grow"
              variant="light"
              className="ms-3 py-2 fw-bold fs-4"
            />
          )}
        </Button>
      </Modal>
    </>
  );
};

export default AddEditNotesModal;
