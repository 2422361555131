import React, { Suspense, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Row, Col, Button } from "react-bootstrap";
import Pagination from "react-bootstrap/Pagination";
import AfterAuth from "../HOC/AfterAuth";
import TableNavbar from "../components/TableNavbar";
import PermissonTable from "../components/Permisson/PermissonTable";
import { permissonTable } from "../helper/API/Permisson";
import Loader from "../components/Loader";
import {
  adminPaginationData,
  toReloadAdminData,
} from "../recoil/PaginationAtoms/AdminList";
import { useRecoilState, useRecoilValue } from "recoil";
import { PAGE_LIMIT } from "../config";
import { loginAtom } from "../recoil/Atoms";

const Table = ({
  tableRow,
  setTableRow,
  activeTab,
  refresh,
  setRefresh,
  search,
  searchResult,
  setSearchResult,
}) => {
  const tableData = useRecoilValue(
    adminPaginationData(searchResult ? search : (search = ""))
  );
  const [reloadVal, reloadData] = useRecoilState(toReloadAdminData);
  const totalPage = Math.ceil((tableData?.totalFindData || 1) / PAGE_LIMIT);

  console.log("tableData", tableData);
  console.log("searchResult", searchResult);
  console.log("refresh", refresh);

  useEffect(() => {
    reloadData(reloadVal + 1);
    setSearchResult(false);
  }, [refresh]);
  // useEffect(() => {
  // 	if (searchResult) {
  // 		setSearchResult(false);
  // 	}
  // }, [searchResult]);
  useEffect(() => {
    setTableRow(tableData?.adminList);
  }, [tableData]);

  return (
    <PermissonTable
      tableRow={tableRow}
      refresh={refresh}
      activeTab={activeTab}
      setRefresh={setRefresh}
      tableDataArray={tableData}
      totalPage={totalPage}
    />
  );
};

const Permissões = () => {
  const [tableRow, setTableRow] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("admin");
  const [searchResult, setSearchResult] = useState(false);

  const login = useRecoilValue(loginAtom);

  const [search, setSearch] = useState();

  const onEnter = (e) => {
    if (e.key === "Enter") {
      // reloadData(reloadVal + 1);
      setSearchResult(true);
    } else {
      // setSearch("");
      setSearchResult(false);
    }
  };

  return (
    <>
      <AfterAuth>
        <h2 className="mt-3 ms-md-5 ms-3">Permissões</h2>
        <Card className="m-2 m-md-5 my-3 p-3 px-md-4">
          <Row>
            {login?.permissions?.newBanker ? (
              <Col md={12}>
                <Button
                  className={`login-btn${
                    activeTab != "admin" ? "-disabled" : ""
                  } px-2 py-1 mx-2 mb-3 mb-md-0 border-0`}
                  onClick={() => setActiveTab("admin")}
                  type="button"
                >
                  Usuário do aplicativo
                </Button>
                <Button
                  className={`login-btn${
                    activeTab != "banker" ? "-disabled" : ""
                  } px-2 py-1 mx-2 border-0`}
                  onClick={() => setActiveTab("banker")}
                  type="button"
                >
                  Usuário do banco
                </Button>
              </Col>
            ) : null}
            <Col className="m-2">
              {/* <NAVBAR /> */}
              <TableNavbar
                title={"Permissões"}
                btn1Text="Pendentes"
                btn2Text="Respondidas"
                btn3Text="Todas"
                setSearch={setSearch}
                activeTab={activeTab}
                onEnter={onEnter}
                refresh={refresh}
                setRefresh={setRefresh}
                search={search}
              />
            </Col>

            {/* table */}
            <Col md={12} className="m-2">
              <Suspense fallback={<Loader />}>
                <Table
                  activeTab={activeTab}
                  searchResult={searchResult}
                  setSearchResult={setSearchResult}
                  setTableRow={setTableRow}
                  tableRow={tableRow.filter(
                    (item) => item.userType === activeTab
                  )}
                  search={search}
                  refresh={refresh}
                  setRefresh={setRefresh}
                />
              </Suspense>
            </Col>
            {/* pagination */}
          </Row>
        </Card>
      </AfterAuth>
    </>
  );
};

export default Permissões;
