import React, { useState, useEffect, Suspense } from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import AfterAuth from "../HOC/AfterAuth";
import TableNavbar from "../components/TableNavbar";
import DocumentTable from "../components/Document/DocumentTable";
import { getDocumentList } from "../helper/API/document";
import Loader from "../components/Loader";
import { documentTableData, profileAtom } from "../recoil/Atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  documentActivePageAtom,
  documentNextPageSelector,
  documentPaginationData,
  documentPrevPageSelector,
  documentShowFirstPageSelector,
  documentShowLastPageSelector,
  toReloadDocumentData,
} from "../recoil/PaginationAtoms/Document";
import { PAGE_LIMIT } from "../config";
// const DocumentTable = React.lazy(() =>
// 	import("../components/Document/DocumentTable")
// );

const Table = ({
  tableRow,
  setTableRow,
  table,
  setTable,
  id,
  setId,
  open,
  setOpen,
  handleShowRow,
  idArray,
  // tableData,
  // totalPage,
  search,
  refresh,
  setRefresh,
  searchResult,
  setSearchResult,
}) => {
  const tableData = useRecoilValue(
    documentPaginationData(searchResult ? search : (search = ""))
  );
  const [reloadVal, reloadData] = useRecoilState(toReloadDocumentData);
  const totalPage = Math.ceil(
    (tableData?.totalContactDetails || 1) / PAGE_LIMIT
  );
  // const [tableRow, setTableRow] = useState(tableData?.findContactData);

  console.log("tableData", tableData);
  console.log("tableRow", tableRow);

  useEffect(() => {
    reloadData(reloadVal + 1);
    setSearchResult(false);
  }, [refresh]);

  useEffect(() => {
    setTable(tableData?.findContactData);
    setTableRow(tableData?.findContactData);
  }, [tableData]);

  return (
    <DocumentTable
      tableRow={tableRow}
      refresh={refresh}
      setRefresh={setRefresh}
      id={id}
      setId={setId}
      open={open}
      setOpen={setOpen}
      handleShowRow={handleShowRow}
      idArray={idArray}
      tableDataArray={tableData}
      totalPage={totalPage}
    />
  );
};

const Documents = () => {
  const profile = useRecoilValue(profileAtom);
  const [table, setTable] = useRecoilState(documentTableData);
  const [tableRow, setTableRow] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [loading, setLoading] = useState(false);

  const [active, setActive] = useState({
    rejected: false,
    pending: false,
    approved: false,
    all: true,
  });
  const [search, setSearch] = useState("");
  const [id, setId] = useState(null);
  const [open, setOpen] = useState(false);
  const [idArray, setIdArray] = useState([]);
  //   const [show, setShow] = useState(false);
  const [searchResult, setSearchResult] = useState(false);

  console.log("search", search);

  const onEnter = (e) => {
    if (e.key === "Enter") {
      // reloadData(reloadVal + 1);
      setSearchResult(true);
    } else {
      // setSearch("");
      setSearchResult(false);
    }
  };

  const handleToggle = (status) => {
    if (status === "Pending") {
      setActive({
        rejected: false,
        pending: true,
        approved: false,
        all: false,
      });

      const newData = table.filter((obj) => {
        if (obj.allStatus === "pending") {
          return obj;
        }
      });
      setTableRow(newData);
    } else if (status === "Approved") {
      setActive({
        rejected: false,
        pending: false,
        approved: true,
        all: false,
      });
      const newData = table.filter((obj) => {
        if (obj.allStatus === "approved") {
          return obj;
        }
      });
      setTableRow(newData);
    } else if (status === "rejected") {
      setActive({
        rejected: true,
        pending: false,
        approved: false,
        all: false,
      });
      const newData = table.filter((obj) => {
        if (obj.allStatus === "wait") {
          return obj;
        }
      });
      setTableRow(newData);
    } else {
      setActive({
        rejected: false,
        pending: false,
        approved: false,
        all: true,
      });
      setTableRow(table);
    }
  };

  const handleShowRow = (id) => {
    setOpen(!open);
    setId(id);

    if (idArray.includes(id)) {
      var index = idArray.indexOf(id);
      if (index !== -1) {
        idArray.splice(index, 1);
      }
    } else {
      setIdArray((old) => [...old, id]);
    }
  };

  useEffect(() => {
    if (profile.userType === "banker") {
      handleToggle("Approved");
    }
  }, [table]);

  return (
    <>
      <AfterAuth>
        <h2 className="mt-3 ms-md-5 ms-3">Documentos</h2>
        <Card className="m-2 m-md-5 my-3 p-3 px-md-4">
          <div style={{ paddingRight: "2%" }}>
            <TableNavbar
              title={"Documentos"}
              btn1Text="Respondidas"
              btn2Text="Pendentes"
              btn3Text="Todas"
              setSearch={setSearch}
              onEnter={onEnter}
              refresh={refresh}
              setRefresh={setRefresh}
              search={search}
            >
              {profile.userType === "admin" ? (
                <>
                  <Button
                    className={`fs-color ms-1 border-0 ${
                      active.rejected ? "activeBtnTable" : "inActiveBtnTable"
                    }`}
                    onClick={(e) => handleToggle("rejected")}
                  >
                    Aguard.&nbsp;rev.
                  </Button>
                  <Button
                    className={`fs-color border-0 ${
                      active.approved ? "activeBtnTable" : "inActiveBtnTable"
                    }`}
                    onClick={(e) => handleToggle("Approved")}
                  >
                    Concluídos
                  </Button>
                  <Button
                    className={`fs-color border-0 ${
                      active.pending ? "activeBtnTable" : "inActiveBtnTable"
                    }`}
                    onClick={(e) => handleToggle("Pending")}
                  >
                    Aguard.&nbsp;doc.
                  </Button>
                  <Button
                    className={`fs-color px-3 border-0 ${
                      active.all ? "activeBtnTable" : "inActiveBtnTable"
                    }`}
                    onClick={(e) => handleToggle("All")}
                  >
                    Todas
                  </Button>
                </>
              ) : null}
            </TableNavbar>
          </div>

          {/* {loading ? (
						<Loader />
					) : ( */}
          <Suspense fallback={<Loader />}>
            <Table
              search={search}
              refresh={refresh}
              setRefresh={setRefresh}
              table={table}
              setTable={setTable}
              tableRow={tableRow}
              setTableRow={setTableRow}
              id={id}
              setId={setId}
              open={open}
              setOpen={setOpen}
              handleShowRow={handleShowRow}
              idArray={idArray}
              searchResult={searchResult}
              setSearchResult={setSearchResult}
            />
          </Suspense>
          {/* )} */}
        </Card>
      </AfterAuth>
    </>
  );
};

export default Documents;
